@import "colors";

$blue: $ephemic;
$font-family-sans-serif: "Roboto", sans-serif;
$headings-font-weight: 700;
$grid-gutter-width: 60px;

@import "bootstrap/bootstrap";

.display-4 {
  font-size: 2rem;
}

h3 {
  margin-top: 2.5rem;
  font-size: 1rem;
}

.header {
  background-image: url(/images/carabiner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 3rem;
  padding-top: 1.2rem;

  @include media-breakpoint-up(sm) {
    padding-top: 4rem;
  }

  .logo img {
    height: 40px;
    width: 82.11px;
    padding: 0;
  }

  .rs-nav a {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.9rem;
    letter-spacing: 0.75px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding-left: 1rem;
    color: $winter;
    text-decoration: none;
    padding: 0.5rem 1rem;
    padding-right: 0.5rem;

    &.active,
    &:hover {
      color: $serenity;
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding-top: 4rem;
    color: desaturate($cachaca, 30%);
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 300;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: desaturate($cachaca, 30%);
  }
}

.footer {
  background-color: darken($vangolu, 22.5%);
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: darken($winter, 50%);
}

.col-figure {
  width: 50%;
  margin: 0 auto;
}

.grecaptcha-badge {
  display: none;
}
